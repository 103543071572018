import etherFetch from '../ether-fetch/etherFetch';

const BASE_ROUTE = '/order';

/**
 * Returns a promise for creating a cart entry in db
 * @param {Object<Cart>} cart - Cart object to store in DB
 * @param {string} storeSlug - Store slug for which to create the cart
 */
export function createCart(cart, storeSlug) {
  return etherFetch([BASE_ROUTE, 'create'].join('/'), {
    body: {cart, storeSlug},
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
}

/**
 * Returns a promise for adding an item to a cart entry in db
 * @param packId - ID of the pack to add
 * @param orderId - ID of the cart to add to
 * @param {'kiosk'|'storefront'|'cart link'} checkoutMethod - Method of checkout for the item
 * @returns {Promise<*>}
 */
export function addItem(packId, orderId, checkoutMethod) {
  return etherFetch([BASE_ROUTE, 'add'].join('/'), {
    body: {packId, orderId, checkoutMethod},
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
}

/**
 *
 * @param orderId - ID of the order to update
 * @param {'email'|'phone'} contactMethod - New contact method for the order
 * @returns {Promise<*>}
 */
export function setContactMethod(orderId, contactMethod = 'email') {
  return etherFetch([BASE_ROUTE, 'contact-method'].join('/'), {
    body: {orderId, contactMethod},
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * Returns a promise for removing an item from a cart entry in db
 * @param packId - ID of the pack to remove
 * @param orderId - ID of the cart to remove from
 * @returns {Promise<*>}
 */
export function removeItem(packId, orderId) {
  return etherFetch([BASE_ROUTE, 'remove'].join('/'), {
    body: {packId, orderId},
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json());
}

/**
 * Returns a promise for returning a cart entry from db
 * @param {string} id - MongoDB _id to retrieve
 */
export function getOrder(id) {
  return etherFetch([BASE_ROUTE, id].join('/')).then((res) => res.json());
}

/**
 * Returns a promise for a stripe url to create and link a stripe express account
 */
export function createStripeAccount() {
  return etherFetch([BASE_ROUTE, 'stripe-connect', 'create'].join('/'), {
    method: 'POST',
  }).then((res) => res.json());
}

/**
 * Returns a promise for which resolves to a stripe sessions token
 */
export function confirmStripeAccount(id) {
  return etherFetch([BASE_ROUTE, 'stripe-connect', 'link'].join('/'), {
    method: 'POST',
    body: {id},
  }).then((res) => res.json());
}

/**
 * Returns a promise for which resolves to a stripe sessions token
 */
export function createStripeSession(name, cartId) {
  return etherFetch([BASE_ROUTE, 'session'].join('/'), {
    body: {name, cartId},
    method: 'POST',
  }).then((res) => res.json());
}

/**
 * Returns a promise for which resolves to a stripe sessions token
 */
export function getStripeAccountLink() {
  return etherFetch([BASE_ROUTE, 'stripe-connect', 'login'].join('/')).then(
    (res) => res.json(),
  );
}

/**
 *
 * @returns {Array} - Array of orders for the current operator
 */
export function getOrders() {
  return etherFetch(BASE_ROUTE).then((res) => res.json());
}

/**
 *
 * @returns {Object} - Returns detailed order payload (hydraterOrder/payment-intent)
 */
export function getOrderDetails(orderId) {
  return etherFetch(`/order/${orderId}/payment-intent`).then((res) =>
    res.json(),
  );
}

export function refundOrder(orderId, refundData) {
  return etherFetch([BASE_ROUTE, 'refund'].join('/'), {
    method: 'POST',
    body: {
      id: orderId,
      reason: refundData.reason,
    },
  }).then((res) => res.json());
}
