import etherFetch from '../ether-fetch/etherFetch';

/**
 * Given an operator's store slug, return user-surfacable event information
 * @param {string} storeSlug - Operator's store slug
 * @returns {Promise<Object>} - Response from the API
 */
export function getSafeEvents(storeSlug) {
  return etherFetch('/store/events', {
    method: 'POST',
    body: {storeSlug},
  }).then((res) => res.json());
}

/*
 * Given an operator's store slug and an event ID, return user-surfacable event information
 * with associated packs
 * @param {string} storeSlug - Operator's store slug
 * @param {string} eventId - Event ID
 * @returns {Promise<Object>} - Response from the API
 */
export function getSafeEventWithPacks(storeSlug, eventId) {
  return etherFetch('/store/event-with-packs', {
    method: 'POST',
    body: {storeSlug, eventId},
  }).then((res) => res.json());
}

/**
 * Given an operator's store slug and a pack name, return user-surfacable assets
 * @param storeSlug
 * @param packName
 * @returns {Promise<*>}
 */
export function getSafeAssets(packName, eventId) {
  return etherFetch('/store/assets', {
    method: 'POST',
    body: {packName, eventId},
  }).then((res) => res.json());
}
