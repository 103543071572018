import {func, object, string, bool, array} from 'prop-types';
import React from 'react';
import {View, Text} from 'react-native';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import {useTheme} from '../context/ThemeContext';

EtherDropdown.propTypes = {
  options: array.isRequired,
  text: string,
  description: string,
  selected: object,
  setSelected: func,
  creatable: bool,
  isMulti: bool,
};

export default function EtherDropdown({
  title,
  description,
  placeholder,
  options,
  selected,
  setSelected,
  creatable = false,
  isMulti = false,
  style: overrides,
}) {
  const {style, values} = useTheme(getThemedStyles);

  const customStyle = {
    option: (provided, state) => {
      return {
        ...provided,
        zIndex: 9999,
        fontFamily: 'NotoSans_Bold',
        fontSize: 12,
        color: state.isSelected
          ? values.LIGHT
          : state.isFocused
          ? values.LIGHT
          : values.DARK,
        backgroundColor: state.isSelected
          ? values.FIRST
          : state.isFocused
          ? values.SECOND
          : values.BGSECOND,
        padding: 10,
        ':active': {
          color: 'white',
          backgroundColor:
            !state.isDisabled &&
            (state.isSelected ? values.SECOND : values.FIRST),
        },
      };
    },
    control: (provided) => {
      return {
        ...provided,
        //flex: 1,
        zIndex: 9999,
        width: '100%',
        minHeight: 50,
        height: '100%',
        fontFamily: 'NotoSans_Regular',
        fontSize: 12,
        borderColor: values.FIRST,
        borderWidth: 2,
        boxShadow: 'none',
        ':hover': {
          borderColor: values.FIRST,
        },
      };
    },
  };

  return (
    <View style={[style.dropdown, overrides]}>
      <Text style={style.title}>{title}</Text>
      <Text style={style.description}>{description}</Text>
      {creatable ? (
        <Creatable
          isMulti
          placeholder={placeholder}
          options={options}
          onChange={setSelected}
          value={selected}
          styles={customStyle}
          noOptionsMessage={() => <Text>No options</Text>}
        />
      ) : (
        <Select
          isMulti={isMulti}
          placeholder={placeholder}
          options={options}
          onChange={setSelected}
          value={selected}
          styles={customStyle}
          noOptionsMessage={() => <Text>No options</Text>}
        />
      )}
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  description: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 12,
    color: theme.DARK,
  },
  dropdown: {
    zIndex: 9999,
    width: '100%',
  },
  title: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 16,
    color: theme.DARK,
  },
});
