import {func, string} from 'prop-types';
import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';
import {Pressable, View, Text, Modal} from 'react-native';

import EtherButton from './EtherButton';
import EtherDropdown from './EtherDropdown';
import {useTheme} from '../context/ThemeContext';
import {refundOrder, getOrderDetails} from '../utils/common/orders';

RefundModal.propTypes = {
  onPressClose: func.isRequired,
  orderId: string.isRequired,
  onRefundComplete: func,
};

export default function RefundModal({onPressClose, orderId, onRefundComplete}) {
  const {style} = useTheme(getThemedStyles);
  const [refundStatus, setRefundStatus] = useState('loading');
  const [isFraudulent, setIsFraudulent] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);

  useEffect(() => {
    async function checkRefundStatus() {
      try {
        const orderDetails = await getOrderDetails(orderId);
        setRefundStatus(
          orderDetails.hydratedOrder.refunded ? 'refunded' : 'not_refunded',
        );
      } catch (err) {
        console.error('Error fetching refund status:', err);
        setRefundStatus('error');
      }
    }

    checkRefundStatus();
  }, [orderId]);

  const refundOptions = [
    {value: 'duplicate', label: 'Duplicate purchase'},
    {value: 'fraudulent', label: 'Fraudulent'},
    {value: 'requested_by_customer', label: 'Requested by costumer'},
  ];

  const getStatusDisplay = (status) => {
    switch (status) {
      case 'loading':
        return {
          text: 'Loading status...',
          color: 'gray',
        };
      case 'not_refunded':
        return {
          text: 'Not refunded',
          color: 'green',
        };
      case 'refunded':
        return {
          text: 'Refunded',
          color: 'red',
        };
      case 'error':
        return {
          text: 'Error loading status',
          color: 'red',
        };
      default:
        return {
          text: 'Unknown status',
          color: 'red',
        };
    }
  };

  const statusDisplay = getStatusDisplay(refundStatus);

  async function handleRefund() {
    try {
      const response = await refundOrder(orderId, {
        reason: selectedReason?.value,
      });
      console.log('Refund response:', response);
      toast.success('Order refunded successfully');
      onRefundComplete();
      onPressClose();
    } catch (err) {
      toast.error(`Error refunding order: ${err.message}`);
    }
  }

  return (
    <Modal
      style={style.modal}
      animationType="fade"
      transparent
      ariaHideApp={false}
    >
      <Pressable style={style.outerArea} onPress={onPressClose}>
        <View
          style={style.container}
          onTouchEnd={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <Text style={style.title}>Refund Menu</Text>
          <Text style={style.statusText}>
            Refund Status:{' '}
            <Text style={{color: statusDisplay?.color}}>
              {statusDisplay?.text}
            </Text>
          </Text>
          <View style={style.dropdown}>
            <Text style={style.dropdownText}>Provide a reason for refund</Text>
            {isFraudulent ? (
              <Text style={style.fraudText}>
                This option will block this payment method permanently
              </Text>
            ) : null}
            <EtherDropdown
              options={refundOptions}
              selected={selectedReason}
              setSelected={(option) => {
                setSelectedReason(option);
                setIsFraudulent(option?.value === 'fraudulent');
              }}
              placeholder="Select a reason"
            />
          </View>
          <View style={style.buttons}>
            <EtherButton
              text="Cancel"
              textColor="blurple"
              style={style.cancelButton}
              onPress={onPressClose}
            />
            <EtherButton
              text="Refund"
              textColor="light"
              onPress={handleRefund}
            />
          </View>
        </View>
      </Pressable>
    </Modal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttons: {
    flexDirection: 'row',
  },
  cancelButton: {
    borderColor: theme.FIRST,
    backgroundColor: theme.LIGHT,
    marginRight: 15,
    width: 80,
  },
  container: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    margin: 'auto',
    borderRadius: 5,
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 300,
    width: 400,
    padding: 20,
    cursor: 'default',
  },
  dropdown: {
    zIndex: 9999,
    minWidth: 320,
  },
  dropdownText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    paddingVertical: 10,
    color: theme.DARK,
  },
  fraudText: {
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.RED,
    marginTop: -10,
    marginBottom: 5,
  },
  modal: {
    zIndex: 9999,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modeHeader: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    marginBottom: 20,
  },
  modeSubText: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginBottom: 20,
  },
  modeTextContainer: {
    alignSelf: 'center',
    height: 'auto',
  },
  outerArea: {
    backgroundColor: theme.BLACKGROUND,
    flex: 1,
  },
  renameContainer: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    margin: 'auto',
    borderRadius: 5,
    height: 250,
    width: 460,
    padding: 20,
    cursor: 'default',
  },
  statusText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginTop: -20,
  },
  title: {
    fontSize: fontSize.header,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
});
