import {
  faExclamationTriangle,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {oneOfType, arrayOf, object, number, string, shape} from 'prop-types';
import React, {useState} from 'react';
import {View, FlatList, Image, TouchableOpacity, Text} from 'react-native';

import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {useIsMobile} from '../utils/common/hooks';

DownloadSummary.propTypes = {
  style: oneOfType([object, number]),
  snapshotAssets: arrayOf(
    shape({
      url: string,
      name: string,
      mime: string,
    }),
  ).isRequired,
};
export default function DownloadSummary({style: overrides, snapshotAssets}) {
  const {isMobile} = useIsMobile();
  const {style, values} = useTheme(getThemedStyles);
  const [extended, setExtended] = useState(false);
  const [imageErrors, setImageErrors] = useState({});
  const [hasAnyImageError, setHasAnyImageError] = useState(false);
  const columns = isMobile ? 2 : 4;

  const handleImageError = (itemName) => {
    setImageErrors((prev) => ({
      ...prev,
      [itemName]: true,
    }));
    setHasAnyImageError(true);
  };

  return (
    <View style={overrides}>
      {hasAnyImageError ? (
        <View style={style.errorMessageLine}>
          <FaIcon
            size={24}
            icon={faExclamationTriangle}
            color={values.YELLOW}
          />
          <Text style={style.errorMessageText}>
            Some preview images could not be loaded
          </Text>
        </View>
      ) : null}
      <View style={style.imageZone}>
        <FlatList
          data={extended ? snapshotAssets : snapshotAssets.slice(0, 4)}
          renderItem={({item}) => (
            <View>
              {item.url && !imageErrors[item.name] ? (
                <Image
                  style={style.image}
                  source={item.url}
                  onError={() => handleImageError(item.name)}
                />
              ) : (
                <View style={style.placeholderTile}>
                  <Text style={style.placeholderTitle}>
                    Preview Unavailable
                  </Text>
                  <Text style={style.placeholderName}>{item.name}</Text>
                  <Text style={style.placeholderMime}>{item.mime}</Text>
                </View>
              )}
            </View>
          )}
          numColumns={columns}
          key={columns}
        />
        {snapshotAssets.length > 4 ? (
          <View style={style.moreContainer}>
            <TouchableOpacity
              onPress={() => setExtended(!extended)}
              style={style.moreButton}
            >
              <FaIcon icon={extended ? faMinus : faPlus} />
              <Text>
                {extended ? 'show less' : `${snapshotAssets.length - 4} more`}
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  assetName: {
    fontSize: fontSize.legal,
    textAlign: 'center',
    marginTop: 4,
    fontFamily: 'NotoSans_Regular',
  },
  errorMessageLine: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  errorMessageText: {
    marginLeft: 5,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.DARK,
  },
  image: {
    height: 180,
    width: 180,
    marginRight: 10,
    marginTop: 10,
    borderRadius: 10,
    mobile: {
      height: 160,
      width: 160,
    },
  },
  imageZone: {
    alignItems: 'center',
  },
  moreButton: {
    alignItems: 'center',
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.DARK,
  },
  moreContainer: {
    alignItems: 'center',
    height: 80,
    padding: 20,
    justifyContent: 'space-between',
  },
  placeholderMime: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    opacity: 0.5,
  },
  placeholderName: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
  },
  placeholderText: {
    color: theme.LIGHT,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
    textAlign: 'center',
  },
  placeholderTile: {
    backgroundColor: theme.DARK,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 3,
    borderColor: theme.LIGHT,
    borderStyle: 'dashed',
    height: 180,
    width: 180,
    marginRight: 10,
    marginTop: 10,
    borderRadius: 10,
    padding: 10,
    mobile: {
      height: 160,
      width: 160,
    },
  },
  placeholderTitle: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
    textAlign: 'center',
    marginBottom: 20,
  },
});
