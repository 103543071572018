import React, {useEffect, useState} from 'react';
import {View, Text, ActivityIndicator} from 'react-native';

import {FilterMenu} from '../../components/table-list/FilterMenu';
import TableList from '../../components/table-list/TableList.web';
import TableListMobile from '../../components/table-list-mobile/TableListMobile.web';
import {useTheme} from '../../context/ThemeContext';
import {HEADER_HEIGHT} from '../../utils/common/constants';
import {useWindowDimensions} from '../../utils/common/hooks';
import {getOrders} from '../../utils/common/orders';

export default function Orders() {
  const [filters, setFilters] = useState([]);
  const {style, values} = useTheme(getThemedStyles);
  const [orders, setOrders] = useState(null);
  const {isLandscape, isMobile} = useWindowDimensions();
  const filterValues = filters.map((o) => o.value);
  const filteredOrders = orders?.filter((o) => {
    return filterValues.includes(o.eventName);
  });

  useEffect(() => {
    getOrders().then(setOrders);
  }, []);

  /** Loading */
  if (!orders)
    return (
      <View style={style.loadWheelView}>
        <ActivityIndicator size="large" color={values.FIRST} />
      </View>
    );
  if (orders && orders.length === 0) {
    return (
      <View style={style.mainContainer}>
        <Text style={style.noOrders}>No orders here... yet!</Text>
      </View>
    );
  }

  /** Main Content */
  const showMobile = !isLandscape && isMobile;
  if (showMobile) {
    return (
      <View style={style.mainContainer}>
        <TableListMobile data={orders} />
      </View>
    );
  }
  return (
    <>
      <FilterMenu orders={orders} filter={filters} setFilter={setFilters} />
      <View style={style.tableContainer}>
        <TableList
          style={style.table}
          pageSize={11}
          data={filteredOrders.length > 0 ? filteredOrders : orders}
        />
      </View>
    </>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  loadWheelView: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  noOrders: {
    textAlign: 'center',
    paddingHorizontal: 20,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.DARK,
    alignSelf: 'center',
    marginTop: 320,
  },
  table: {
    width: '100%',
    minWidth: 750,
  },
  tableContainer: {
    flex: 1,
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    mobile: {
      padding: 10,
      backgroundColor: theme.BGFIRST,
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
    },
  },
});
