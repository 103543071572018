import {
  faCircleExclamation,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, Linking} from 'react-native';

import CartSummary from '../../components/CartSummary.web';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import PaymentWeb from '../../components/PaymentWeb';
import ReportModal from '../../components/ReportModal';
import SimpleDropdown from '../../components/SimpleDropdown.web';
import StripeCheckout from '../../components/StripeCheckout';
import Checkbox from '../../components/checkbox/Checkbox';
import {useTheme} from '../../context/ThemeContext';
import {getOrderExpiryCountdown} from '../../utils/common/funcs';
import {getOrder, setContactMethod} from '../../utils/common/orders';

export default function Checkout({route, navigation: {navigate}}) {
  const {style, values, fontSize} = useTheme(getThemedStyles);
  const [cart, setCart] = useState(null);
  const orderId = route?.params?.cart;
  const [loadMessage, setLoadMessage] = useState('Loading order data...');
  const [errorMessage, setErrorMessage] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [daysToExpire, setDaysToExpire] = useState(0);
  const [hoursToExpire, setHoursToExpire] = useState(0);
  const [phoneConsent, setPhoneConsent] = useState(false);

  const hideSmsConsent =
    cart?.checkoutMethod === 'kiosk' && cart?.contactMethod === 'sms';

  const menuEntries = [
    {
      title: 'Report a problem',
      faIcon: faCircleExclamation,
      callback: () => setShowReportModal(true),
    },
  ];
  const reportData = {
    cartDate: cart?.date,
    cartId: orderId,
    eventId: cart?.eventId,
    eventName: cart?.eventName,
    userId: cart?.userId,
    username: cart?.username,
    purchased: cart?.purchased,
    url: window.location.href,
  };

  useEffect(() => {
    getOrder(orderId)
      .then((orderData) => {
        setCart(orderData);
        setLoadMessage(null);
        if (orderData.purchased !== null) {
          navigate('Download', {cart: orderId});
        }

        if (orderData.cartExpiresAt) {
          const {days, hours} = getOrderExpiryCountdown(
            orderData.cartExpiresAt,
          );
          setDaysToExpire(days);
          setHoursToExpire(hours);
        }
      })
      .catch(() => {
        setLoadMessage(`Failed to retrieve cart with id: ${orderId}`);
        setErrorMessage(
          'Please contact the organization providing your purchase for assistance',
        );
      });
  }, [orderId, navigate]);

  if (loadMessage) {
    return (
      <>
        <Text style={style.loadMessage}>{loadMessage}</Text>
        <Text style={style.errorMessage}>{errorMessage}</Text>
      </>
    );
  }

  function navigateToPage(page) {
    if (page === 'tos') {
      Linking.openURL('home?terms=true');
    }
    if (page === 'pp') {
      Linking.openURL('home?privacy=true');
    }
  }

  if (cart?.cartExpired) {
    return (
      <View style={style.main}>
        <View style={style.cartExpiredCard}>
          <Text style={style.cartExpiredText}>This cart has expired</Text>
        </View>
      </View>
    );
  }

  return (
    <View style={style.main}>
      <ReportModal
        reportData={reportData}
        show={showReportModal}
        onClose={() => setShowReportModal(false)}
        onSuccess={() => {
          toast.success(`Thank you for your report!`);
          setShowReportModal(false);
          navigate('Home');
        }}
        onFail={() => {
          toast.error(`Failed to send report`);
          setShowReportModal(false);
          navigate('Home');
        }}
      />
      <View style={style.packs}>
        <CartSummary cart={cart} />
      </View>
      <View style={style.info}>
        <PaymentWeb cart={cart} />
        <View style={{alignItems: 'center'}}>
          {hideSmsConsent ? null : (
            <View
              style={{
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'row',
                padding: 12,
                borderRadius: 8,
                marginLeft: 24,
                marginBottom: 20,
              }}
            >
              <Checkbox
                checked={phoneConsent}
                onChange={(e) => {
                  const {checked} = e.target;
                  setContactMethod(orderId, checked ? 'sms' : 'email')
                    .then(() => {
                      setPhoneConsent(checked);
                    })
                    .catch(() => {
                      toast.error(
                        `Failed to update contact method, please try again`,
                      );
                    });
                }}
              />
              <Text
                style={{
                  fontSize: fontSize.legal,
                  color: values.DARK,
                  marginLeft: 8,
                }}
              >
                SMS opt-in: By checking this box, you consent to receive SMS
                notifications regarding your purchase. This is limited to a
                confirmation SMS upon successful purchase, and an SMS for
                receiving your purchase. Reply STOP to opt-out at any time.
              </Text>
            </View>
          )}
          <View style={style.buttonRow}>
            <StripeCheckout
              textStyle={style.checkoutText}
              buttonText="Checkout"
              style={style.checkoutButon}
              cart={cart}
              navigation={{navigate}}
            />
            <EtherButton
              style={style.menuButton}
              onPress={() => setShowDropdown(!showDropdown)}
            >
              <FaIcon icon={faEllipsisVertical} size={24} style={style.icon} />
              {showDropdown ? (
                <SimpleDropdown
                  style={style.dropdown}
                  menuItemStyle={style.dropdownItem}
                  entries={menuEntries}
                  onHide={() => setShowDropdown(false)}
                />
              ) : null}
            </EtherButton>
          </View>
          {daysToExpire < 1 ? (
            <Text style={style.termsText}>
              Your cart expires in {hoursToExpire} hours.
            </Text>
          ) : (
            <Text style={style.termsText}>
              Your cart expires in {daysToExpire} days.
            </Text>
          )}
          <Text style={style.termsText}>
            Clicking "Checkout" means you agree to{' '}
            <Text style={style.termsLink} onPress={() => navigateToPage('tos')}>
              our terms
            </Text>
            <Text> and </Text>
            <Text style={style.termsLink} onPress={() => navigateToPage('pp')}>
              privacy policy
            </Text>
            .
          </Text>
        </View>
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonRow: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: 20,
    justifyContent: 'space-between',
  },
  cartExpiredCard: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    padding: 20,
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 15,
    width: '100%',
    maxWidth: 500,
  },
  cartExpiredText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.header,
  },
  checkoutButon: {
    height: 50,
    width: 140,
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
  },
  checkoutText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  dropdown: {
    width: 185,
    height: 35,
    minHeight: 35,
    position: 'absolute',
    backgroundColor: theme.BGFIRST,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: theme.FIRST,
    alignSelf: 'flex-end',
    marginTop: 90,
  },
  dropdownItem: {
    borderRadius: 2,
  },
  errorMessage: {
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    alignSelf: 'center',
    marginTop: 40,
  },
  icon: {
    color: theme.LIGHT,
  },
  info: {
    flex: 1,
    height: 'fit-content',
    maxWidth: 600,
    padding: 20,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: theme.FIRST,
    backgroundColor: theme.BGFIRST,
    alignItems: 'center',
  },
  loadMessage: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
    alignSelf: 'center',
    marginTop: 240,
  },
  main: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 40,
    mobile: {
      flexDirection: 'column',
    },
  },
  menuButton: {
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 6,
  },
  packs: {
    flex: 3,
    maxWidth: 1800,
    padding: 20,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: theme.FIRST,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.BGFIRST,
    marginRight: 20,
    mobile: {
      flex: null,
      marginRight: 0,
      marginBottom: 20,
    },
  },
  termsLink: {
    color: theme.FIRST,
    textDecorationLine: 'underline',
  },
  termsText: {
    textAlign: 'center',
    color: theme.DARK,
    zIndex: -1,
  },
});
